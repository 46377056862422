import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { InputField } from "components/input/Text";
import { useDispatch, useSelector } from "react-redux";
import { buyGiftCard } from "redux/action/userAction";
import { useAppContext } from "contexts/appcontext";
import { usePaystack } from "hooks/usePaystack";
import giftcardImage from "../../../assets/images/giftcard.png";
import { OverlayLoader } from "components/Loaders";
import { format } from "utils/helpers";

const GiftCard = () => {
  const [amount, setAmount] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const dispatch = useDispatch();

  const { setOpenSignin } = useAppContext();
  const { isLoggedIn, user, giftCard } = useSelector(({ user }) => user);

  useEffect(() => {
    if (!isLoggedIn) {
      setOpenSignin(true);
    } else {
      setUserEmail(user.email);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, user.email]);

  const onChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setAmount(value);
    }
  };

  const onSubmit = () => {
    if (!userEmail) {
      return setOpenSignin(true);
    }
    initializePayment(() => {
      return buyGiftCard({ paymentRef, amount })(dispatch);
    });
  };

  const paymentRef = Math.random().toString().slice(2, 11);

  const { initializePayment } = usePaystack(userEmail, amount, paymentRef);

  const reset = () => {
    setAmount("");
    dispatch({ type: "GIFT_CARD_RESET" });
  };

  if (giftCard.loading) {
    return <OverlayLoader />;
  }

  return giftCard.created ? (
    <div className="max-w-2xl mb-12 mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h3 className="font-bold text-2xl my-4 text-gray-700 text-center">
        Thank you for purchasing a gift card
      </h3>
      <p className="text-center">
        You will receive a mail with the details of your purchase, you can also
        screen grab this image.
      </p>
      <p className="text-center text-red-600">
        Anyone can use this code to purchase a product on our website. Please
        keep it safe.
      </p>
      <div className="w-full py-6 text-center">
        <div className="w-72 h-96 mb-10 mx-auto relative">
          <div className="absolute z-20 bottom-0 flex flex-col items-center justify-center w-full">
            <QRCode
              bgColor="#000"
              fgColor="#fff"
              size={150}
              value={giftCard.data.code}
              className="z-30"
            />
            <p className="text-white font-bold text-xl my-2">
              {giftCard.data.code}
            </p>
            <p className="text-white font-bold text-xl mb-6">
              {format(giftCard.data.remainingBalance)}
            </p>
          </div>
          <img
            src={giftcardImage}
            className="w-96 absolute z-0 rounded-lg"
            alt="Gift Card"
          />
        </div>
        <div className="flex space-x-4 justify-center">
          <button
            onClick={() => window.location.href = "/"}
            className="border border-gray-800 w-1/3"
          >
            Go Home
          </button>
          <button
            onClick={reset}
            className="rounded-md border border-transparent w-1/3 bg-green-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700"
          >
            Buy Another
          </button>
        </div>
      </div>
    </div>
  ) : (
    <div className="max-w-2xl mb-12 mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div className="flex justify-center border border-gray-200 shadow-xl mt-10">
        <div className="w-1/2 hidden md:block">
          <img
            src="https://res.cloudinary.com/db7anhor6/image/upload/v1702094114/How_to_order_4_jynne4.png"
            alt="Gift Card"
            className="w-full h-full object-center object-cover lg:w-full lg:h-full"
          />
        </div>
        <div className="md:w-1/2 space-y-4 flex flex-col items-center justify-center">
          <h3 className="font-bold text-2xl my-4 text-gray-700 text-center">
            Buy a gift card
          </h3>
          <div className="w-full">
            <InputField
              classes={"mx-auto"}
              onChange={onChange}
              name="amount"
              value={amount}
            />
          </div>
          <div className="w-full py-6 text-center">
            <button
              onClick={onSubmit}
              disabled={
                !amount ||
                !userEmail ||
                giftCard.loading
              }
              className="rounded-md border border-transparent w-3/4 bg-green-600 px-6 py-3 text-base font-medium text-white shadow-sm hover:bg-green-700"
            >
              Pay ₦{amount}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GiftCard;
