import React, { createContext, useContext, useState } from "react";

export const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [openSizeGuide, setOpenSizeGuide] = useState(false);
  const [viewProduct, setViewProduct] = useState(false);
  const [openSignin, setOpenSignin] = useState(false);
  const [currentProduct, setCurrentProduct] = useState(null);
  const [openCart, setOpenCart] = useState(false);
  const [open, setOpen] = useState(false);

  return (
    <AppContext.Provider
      value={{
        openSizeGuide,
        viewProduct,
        currentProduct,
        setCurrentProduct,
        setOpenSizeGuide,
        setViewProduct,
        openSignin,
        setOpenSignin,
        openCart,
        setOpenCart,
        open,
        setOpen
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw new Error("useAppContext must be used within AppContextProvider");
  }
  return context;
};

export default AppContextProvider;
