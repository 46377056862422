import React, { Suspense, lazy } from "react";
import Admin from "pages/Admin";
import AddProduct from "pages/Admin/AddProduct";
import Orders from "pages/Admin/Orders";
import AdminWrapper from "pages/Admin/Wrapper";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ErrorBoundary from "../components/errorBoundary/ErrorBoundary";
import Wrapper from "pages/User/UserWrapper";
import { OverlayLoader } from "components/Loaders";
import Home from "pages/User/Home";
import Shops from "pages/Admin/Shops";
import PrivacyPolicy from "pages/User/PrivacyPolicy";
import GiftCard from "pages/User/GiftCard";

const ViewProducts = lazy(() => import("../pages/User/Product/ViewProducts"));
const Product = lazy(() => import("../pages/User/Product/Product"));
const Checkout = lazy(() => import("../pages/User/Checkout/Checkout"));

const RootRouter = () => {
  return (
    <Suspense fallback={<OverlayLoader />}>
      <ErrorBoundary>
        <Router>
          <Routes>
          <Route
              path="/privacy-policy"
              element={
                <Wrapper>
                  <PrivacyPolicy />
                </Wrapper>
              }
            />
             <Route
              path="/giftcard"
              element={
                <Wrapper>
                  <GiftCard />
                </Wrapper>
              }
            />
          <Route
              path="/all"
              element={
                <Wrapper>
                  <ViewProducts />
                </Wrapper>
              }
            />
            <Route
              path="/men"
              element={
                <Wrapper>
                  <ViewProducts />
                </Wrapper>
              }
            />
            <Route
              path="/women"
              element={
                <Wrapper>
                  <ViewProducts />
                </Wrapper>
              }
            />
            <Route
              path="/"
              element={
                <Wrapper>
                  <Home />
                </Wrapper>
              }
            />
            <Route
              path="/product/:slug"
              element={
                <Wrapper>
                  <Product />
                </Wrapper>
              }
            />
            <Route
              path="/checkout"
              element={
                <Wrapper>
                  <Checkout />
                </Wrapper>
              }
            />
            <Route
              path="/admin"
              element={
                <AdminWrapper>
                  <Admin />
                </AdminWrapper>
              }
            />
            <Route
              path="/admin/addProduct"
              element={
                <AdminWrapper>
                  <AddProduct />
                </AdminWrapper>
              }
            />
            <Route
              path="/admin/editProduct/:id"
              element={
                <AdminWrapper>
                  <AddProduct />
                </AdminWrapper>
              }
            />
            <Route
              path="/admin/orders"
              element={
                <AdminWrapper>
                  <Orders />
                </AdminWrapper>
              }
            />
            <Route
              path="/admin/shops"
              element={
                <AdminWrapper>
                  <Shops />
                </AdminWrapper>
              }
            />
          </Routes>
        </Router>
      </ErrorBoundary>
    
    </Suspense>
  );
};

export default RootRouter;
