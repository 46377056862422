import http from "plugin/http.client";
import { BUY_GIFTCARD, CREATING_GIFTCARD, GET_USER, LOGOUT } from "redux/reducer/user";
import { handleError } from "utils/handleError";

export const getUser = (payload, callback, stopLoading) => async (dispatch) => {
  try {
    const res = await http.post("/auth/google", payload);
    const { data } = res;
    if (data?.token) {
      localStorage.setItem("token", data.token);
      http.defaults.headers.common["Authorization"] = `Bearer ${data.token}`;
    }
    delete data.token;
    localStorage.setItem("user", JSON.stringify(data));
    localStorage.setItem("isLoggedIn", true);
    dispatch({ type: GET_USER, payload: data });
    stopLoading();
    callback(data);
    return res;
  } catch (error) {
    stopLoading();
    handleError(error);
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("isLoggedIn");
  dispatch({
    type: LOGOUT,
  });
};

export const buyGiftCard = (data) => async (dispatch) => {
  dispatch({ type: CREATING_GIFTCARD, payload: true });
  try {
    const res = await http.post(`gift-cards/create`, data);
    dispatch({ type: BUY_GIFTCARD, payload: res.data });
    dispatch({ type: CREATING_GIFTCARD, payload: false });
  } catch (error) {
    handleError(error, dispatch);
    dispatch({ type: CREATING_GIFTCARD, payload: false });
  }
};
