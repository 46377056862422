export const GET_PRODUCTS = "GET_PRODUCTS";
export const GETTING_PRODUCTS = "GETTING_PRODUCTS";
export const GET_SINGLE_PRODUCT = "GET_SINGLE_PRODUCT";
export const LOADING_PRODUCT = "LOADING_PRODUCT";
export const FETCH_PRODUCT = "FETCH_PRODUCT";

const initialState = {
  loadingProduct: false,
  gettingProducts: false,
  products: localStorage.getItem("products")
    ? JSON.parse(localStorage.getItem("products"))
    : [],
};

const product = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOADING_PRODUCT:
      return {
        ...state,
        loadingProduct: payload,
      };
    case GETTING_PRODUCTS:
      return {
        ...state,
        gettingProducts: payload,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: payload,
      };
    case GET_SINGLE_PRODUCT: {
      const { products } = state;

      const singleProductArray = products?.products.filter(
        (product) => product._id === payload
      );
      return {
        ...state,
        singleProduct: singleProductArray[0],
        loadingProduct: false,
      };
    }

    case FETCH_PRODUCT:
      return {
        ...state,
        singleProduct: payload,
        loadingProduct: false,
      };
  
    default:
      return state;
  }
};

export default product;
