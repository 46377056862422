import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Text, { InputField } from "components/input/Text";
import TextArea from "components/input/TextArea";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/outline";
import RadioGroup from "components/input/RadioGroup";
import { createProduct, editProduct, getSingleProduct } from "redux/action/adminActions";
import { CustomPageSectionLoader } from "components/Loaders";

const AddProduct = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [sizes, setSizes] = useState([{ name: "", quantity: "" }]);
  const [colors, setColors] = useState([{ name: "", code: "", link: "" }]);
  const [images, setImages] = useState([""]);
  const [sending, setSending] = useState(false);
  const [highlights, setHighlights] = useState([""]);
  const { singleProduct } = useSelector(({ admin }) => admin);

  useEffect(() => {
    dispatch(getSingleProduct(id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (singleProduct) {
      singleProduct.colors &&
        setColors(
          singleProduct?.colors.map(({ name, code, _id, link }) => ({
            name,
            code,
            _id,
            link
          }))
        );
      singleProduct.sizes &&
        setSizes(
          Object.entries(singleProduct.sizes).map(([name, quantity]) => ({
            name,
            quantity,
          }))
        );
      singleProduct.highlights && setHighlights(singleProduct.highlights);
      singleProduct.images && setImages(singleProduct.images);
    }
  }, [singleProduct]);

  const { values, handleSubmit, handleChange, errors, touched } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: singleProduct?.name || "",
      description: singleProduct?.description ?? "",
      price: singleProduct?.price ?? "",
      mainImage: singleProduct?.mainImage ?? "",
      gender: singleProduct?.gender ?? "",
      chartType: singleProduct?.chartType ?? "",
      category: singleProduct?.category ?? "",
      available: singleProduct?.available ?? "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Required"),
      description: yup.string().required("Required"),
      price: yup.string().required("Required"),
      mainImage: yup.string().required("Required").url("Enter valid url"),
    }),
    onSubmit() {
      const sizesObject = {};
      sizes.forEach(({ name, quantity }) => {
        if (name && quantity) {
          sizesObject[name] = quantity;
        }
      });

      const payload = {
        ...values,
        slug: values.name.replace(/\W+/g, '-'),
        available: !!values.available,
        colors,
        images,
        highlights,
        sizes: sizesObject,
      };

      if (id) {
        return dispatch(editProduct(id, payload, setSending));
      }
      dispatch(createProduct(payload, setSending));
    },
  });

  const handleSizeChange = ({ target: { value, name } }, index) => {
    const sizesCopy = [...sizes];
    sizesCopy[index][name] = value;
    setSizes(sizesCopy);
  };

  const handleColorChange = ({ target: { value, name } }, index) => {
    const colorsCopy = [...colors];
    colorsCopy[index][name] = value;
    setColors(colorsCopy);
  };

  const handleHighlightChange = ({ target: { value } }, index) => {
    const highlightCopy = [...highlights];
    highlightCopy[index] = value;
    setHighlights(highlightCopy);
  };

  const handleImageChange = ({ target: { value } }, index) => {
    const imageCopy = [...images];
    imageCopy[index] = value;
    setImages(imageCopy);
  };
  if (sending) return <CustomPageSectionLoader />;

  return (
    <main className="flex-1">
      <div className="pb-6 h-screen">
        <form
          onSubmit={handleSubmit}
          className="space-y-8 divide-y divide-gray-200"
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between">
            <h1 className="text-2xl font-semibold text-gray-900">
              {id ? "Edit" : "Add"} Product
              {singleProduct && `- ${singleProduct._id}`}
            </h1>
            {/* Action Buttons */}
            <div className="flex">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                Cancel
              </button>
              <button className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                Save
              </button>
            </div>
          </div>

          <FormInner
            data={{
              setImages,
              setColors,
              setHighlights,
              setSizes,
              images,
              sizes,
              colors,
              highlights,
              values,
              handleChange,
              errors,
              touched,
              handleHighlightChange,
              handleColorChange,
              handleSizeChange,
              handleImageChange,
            }}
          />
        </form>
      </div>
    </main>
  );
};

const FormInner = ({ data }) => {
  const {
    setImages,
    setColors,
    setHighlights,
    setSizes,
    images,
    sizes,
    colors,
    highlights,
    values,
    handleChange,
    errors,
    touched,
    handleHighlightChange,
    handleColorChange,
    handleSizeChange,
    handleImageChange,
  } = data;

  return (
    <div className="max-w-7xl mx-auto pb-10 sm:px-6 md:px-8">
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>
          <div className="mt-4 space-y-6 sm:space-y-5">
            <div className="sm:border-b pb-4 sm:border-gray-200">
              <Text
                onChange={handleChange}
                value={values.name}
                name="name"
                label="Product Name"
                error={touched.name && errors.name}
              />
            </div>
            <div className="sm:border-b pb-4 sm:border-gray-200">
              <Text
                onChange={handleChange}
                value={values.price}
                name="price"
                label="Product Price"
                error={touched.price && errors.price}
              />
            </div>
            <div className="sm:border-b pb-4 sm:border-gray-200">
              <TextArea
                onChange={handleChange}
                description="Describe the product"
                name="description"
                value={values.description}
                error={touched.description && errors.description}
              />
            </div>
            <div className="sm:border-b pb-4 sm:border-gray-200">
              <Text
                onChange={handleChange}
                value={values.mainImage}
                name="mainImage"
                label="Main Photo"
                error={touched.mainImage && errors.mainImage}
              />
            </div>
            {/* Other Photos */}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 sm:border-b pb-4 sm:border-gray-200">
              <label className="flex items-center justify-between text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                Other Photos
                <button
                  type="button"
                  onClick={() => setImages([...images, ""])}
                  className="ml-3"
                >
                  <PlusCircleIcon className="h-6 w-6 text-green-700" />
                </button>
              </label>
              <div className="space-y-3">
                {images.map((image, index) => (
                  <div key={index} className="flex space-x-3">
                    <InputField
                      onChange={(e) => handleImageChange(e, index)}
                      name={`image${index}`}
                      value={image}
                    />
                    <button
                      type="button"
                      onClick={() => {
                        const imageCopy = [...images];
                        setImages(imageCopy.filter((sz, idx) => idx !== index));
                      }}
                      className="ml-3"
                    >
                      <MinusCircleIcon className="h-5 w-5 text-red-700" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            {/* Gender */}
            <div className="pt-6 sm:pt-5 sm:border-b pb-4 sm:border-gray-200">
              <RadioGroup
                handleChange={handleChange}
                groupName="gender"
                options={[
                  { value: "male", name: "Male" },
                  { value: "female", name: "Female" },
                  { value: "unisex", name: "Unisex" },
                ]}
                title="Gender"
                value={values.gender}
              />
            </div>
            {/* Availability */}
            <div className="pt-6 sm:pt-5 sm:border-b pb-4 sm:border-gray-200">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                <div>
                  <div className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700">
                    Availability
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <div className="max-w-lg">
                    <label className="mt-4 space-x-4 flex items-center">
                      <input
                        type="checkBox"
                        value={values.available}
                        onChange={handleChange}
                        name="available"
                      />
                      <div>
                        {values.available ? "Available" : "Not Available"}
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* ChartType */}
            <div className="pt-6 sm:pt-5 sm:border-b pb-4 sm:border-gray-200">
              <RadioGroup
                handleChange={handleChange}
                groupName="chartType"
                options={[
                  { value: "typeA", name: "TypeA" },
                  { value: "typeB", name: "TypeB" },
                  { value: "typeC", name: "TypeC" },
                  { value: "typeD", name: "TypeD" },
                ]}
                title="Chart type"
                value={values.chartType}
              />
            </div>
            {/* Category */}
            <div className="pt-6 sm:pt-5 sm:border-b pb-4 sm:border-gray-200">
              <RadioGroup
                handleChange={handleChange}
                groupName="category"
                options={[
                  { value: "tall", name: "Tall" },
                  { value: "petite", name: "Petite" },
                  { value: "regular", name: "Regular" },
                ]}
                title="Category"
                value={values.category}
              />
            </div>

            {/* Colors */}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b pb-4 sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="first-name"
                className="flex items-center justify-between text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Colors
                <button
                  type="button"
                  onClick={() =>
                    setColors([...colors, { name: "", code: "", link: "" }])
                  }
                  className="ml-3"
                >
                  <PlusCircleIcon className="h-6 w-6 text-green-700" />
                </button>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                {colors.map((color, index) => (
                  <div key={index} className="flex space-x-3">
                    <div>
                      Color name
                      <InputField
                        onChange={(e) => handleColorChange(e, index)}
                        name="name"
                        value={color.name}
                      />
                    </div>
                    <div>
                      Color code
                      <InputField
                        onChange={(e) => handleColorChange(e, index)}
                        name="code"
                        value={color.code}
                      />
                    </div>
                    <div>
                      link
                      <InputField
                        onChange={(e) => handleColorChange(e, index)}
                        name="link"
                        value={color.link}
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        const colorsCopy = [...colors];
                        setColors(
                          colorsCopy.filter((col, idx) => idx !== index)
                        );
                      }}
                      className="ml-3"
                    >
                      <MinusCircleIcon className="h-5 w-5 text-red-700" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            {/* Highlights */}
            <div className="sm:border-b pb-4 sm:border-gray-200">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                <label
                  htmlFor={highlights}
                  className="flex items-center justify-between text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Highlights
                  <button
                    type="button"
                    onClick={() => setHighlights([...highlights, ""])}
                    className="ml-3"
                  >
                    <PlusCircleIcon className="h-6 w-6 text-green-700" />
                  </button>
                </label>
                <div>
                  {highlights.map((highlight, index) => (
                    <div className="flex space-x-3 mb-2" key={index}>
                      <InputField
                        onChange={(e) => handleHighlightChange(e, index)}
                        name={"highlight"}
                        value={highlight}
                      />
                      <button
                        type="button"
                        onClick={() => {
                          const highlightCopy = [...highlights];
                          setHighlights(
                            highlightCopy.filter((highl, idx) => idx !== index)
                          );
                        }}
                        className="ml-3"
                      >
                        <MinusCircleIcon className="h-5 w-5 text-red-700" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* Sizes */}
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-b pb-4 sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="first-name"
                className="text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 flex items-center justify-between"
              >
                Sizes
                <button
                  type="button"
                  onClick={() =>
                    setSizes([...sizes, { name: "", quantity: "" }])
                  }
                  className="ml-3"
                >
                  <PlusCircleIcon className="h-6 w-6 text-green-700" />
                </button>
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                {sizes.map((size, index) => (
                  <div className="flex space-x-3" key={index}>
                    <div>
                      Size name
                      <InputField
                        onChange={(e) => handleSizeChange(e, index)}
                        name={"name"}
                        value={size.name}
                      />
                    </div>
                    <div>
                      Quantity
                      <InputField
                        onChange={(e) => handleSizeChange(e, index)}
                        name="quantity"
                        value={size.quantity}
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => {
                        const sizesCopy = [...sizes];
                        setSizes(sizesCopy.filter((sz, idx) => idx !== index));
                      }}
                      className="ml-3"
                    >
                      <MinusCircleIcon className="h-5 w-5 text-red-700" />
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProduct;
