import { combineReducers } from "@reduxjs/toolkit";
import product from "./product";
import cart from "./cart";
import alert from "./alert";
import user from "./user";
import admin from "./admin";

export default combineReducers({
  product,
  cart,
  alert,
  user,
  admin
});
