import { MinusIcon, PlusIcon } from "@heroicons/react/outline";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Home = () => {
  return (
    <div className="pb-20">
      <div className="mx-auto max-w-7xl p-10 bg-orange-900 bg-opacity-20 mb-20 flex flex-col items-center">
        <div className="text-3xl md:text-5xl font-extrabold lg:w-1/3 text-center mb-4">
          Welcome
        </div>
        <div className="md:text-lg text-gray-600 text-center">
          Add affordable staples to your wardrobe
        </div>
      </div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div
          style={{
            backgroundImage: "url('images/sketch.png')",
            backgroundSize: "contain",
          }}
          className="mx-auto max-w-5xl shadow-lg border border-gray-200 rounded-2xl p-4 md:p-8 mb-16"
        >
          <div className="line-through text-2xl md:text-3xl text-gray-500 text-center">
            Fast Fashion
          </div>
          <div className="text-3xl md:text-5xl text-gray-900 text-center py-4 mb-2">
            Intermediate Fashion
          </div>
          <div className="line-through text-2xl md:text-3xl text-gray-500 text-center">
            Slow Fashion
          </div>
        </div>
        <div className="mx-auto max-w-5xl shadow-lg border border-gray-200 rounded-2xl mb-16">
          <div className="grid grid-cols-2 h-56">
            <NavLink
              style={{
                backgroundImage: "url('images/men.png')",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "left 5% center",
                backgroundColor: "#000000",
              }}
              className="text-end text-xl md:text-5xl hover:shadow-lg"
              to="/men"
            >
              <div className="h-full w-full bg-white bg-opacity-50 p-5 flex items-center justify-center text-center">
                Shop Men
              </div>
            </NavLink>
            <NavLink
              style={{
                backgroundImage: "url('images/women.png')",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "right 5% center",
              }}
              className="text-end text-xl md:text-5xl hover:shadow-lg"
              to="/women"
            >
              <div className="h-full w-full bg-white bg-opacity-50 p-5 flex items-center justify-center text-center">
                Shop Women
              </div>
            </NavLink>
          </div>
        </div>
        <div className="relative cursor-pointer mx-auto max-w-5xl h-44 md:h-56 shadow-lg border border-gray-200 rounded-2xl mb-16">
          <div className="absolute bottom-0 pl-4">
            <img src="https://res.cloudinary.com/db7anhor6/image/upload/v1702893376/1_olsgv1.png" alt="add voucher" className="sm:w-44 w-32 md:w-56" />
          </div>
          <NavLink to="/giftcard">
            <div className="flex items-center sm:justify-center justify-end text-end h-full w-full text-xl md:text-5xl hover:shadow-lg">
              <div className="p-5 text-right md:text-center">
                Buy Gift Card
              </div>
            </div>
          </NavLink>
          <div className="absolute right-0 pr-4 top-0 hidden lg:block">
            <img src="https://res.cloudinary.com/db7anhor6/image/upload/v1702893376/2_wrroaf.png" alt="add voucher" className="w-52" />
          </div>
        </div>
        <div className="mx-auto max-w-5xl border shadow-lg border-gray-200 rounded-2xl p-4 md:p-8">
          <h3 className="text-2xl mb-6">Frequently Asked Questions</h3>
          <FAQ
            question="How do I choose the right size for me?"
            answers={[
              "We have a size guide for each item which will be updated as we get new information. However, if you're unclear on what size to purchase, reach out to us on instagram or whatsapp and we will guide you through your purchase.",
            ]}
          />
          <FAQ
            question="What is your return policy?"
            answers={[
              "We offer refunds for damaged items or any faults from us.",
              "We offer exchange for undersized items.",
              "For general displeasure, we will need more information to determine the next step.",
            ]}
          />
          <FAQ
            question="how does Pre-Order work?"
            answers={[
              "You can order an item that is not available in your size. Processing takes 7 - 10 days.",
            ]}
          />
          <FAQ
            question="What if I receive a damaged or defective item?"
            answers={["You can exchange", "You can request a refund."]}
          />
          <hr className="mb-6" />
          <b>All in all,</b> what you see is what you get and we are very
          pleased with every item we put out. You can always reach out to us and
          have as long a conversation as you want with us about our items. We
          will help as much as possible to avoid all foreseeable unpleasantness
          😀.
        </div>
      </div>
    </div>
  );
};

const FAQ = ({ question, answers }) => {
  const [showAnswer, setShowAnswer] = useState(false);

  return (
    <div className="mb-3 border-t border-gray-200 py-4">
      <h5
        onClick={() => setShowAnswer(!showAnswer)}
        className="font-semibold cursor-pointer flex justify-between items-center mb-1"
      >
        {question} {showAnswer ? <MinusIcon className="h-4 w-4" /> : <PlusIcon className="h-4 w-4" />}
      </h5>
      {showAnswer && (
        <ul>
          {answers.map((answer) => (
            <li className="pl-3 text-sm text-gray-700">{answer}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Home;
