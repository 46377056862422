import { GIFT_CARD_RESET } from "./user";

export const ADD_TO_CART = "ADD_TO_CART";
export const GET_CART = "GET_CART";
export const CREATE_ORDER_SUCCESS = "CREATE_ORDER_SUCCESS";
export const RESET_CART = "RESET_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const LOADING_CART = "LOADING_CART";
export const ADD_TO_TEMP_CART = "ADD_TO_TEMP_CART";
export const REMOVE_TEMP_FROM_CART = "REMOVE_TEMP_FROM_CART";
export const CLEAR_TEMP_CART = "CLEAR_TEMP_CART";
export const CREATING_CART = "CREATING_CART";
export const REMOVING_FROM_CART = "REMOVING_FROM_CART";
export const FETCHING_GIFTCARD = "FETCHING_GIFTCARD";
export const GIFT_CARD = "GIFT_CARD";

const initialState = {
  cart: localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : {},
  tempCart: localStorage.getItem("tempCart")
    ? JSON.parse(localStorage.getItem("tempCart"))
    : {},
  orderCreated: false,
  loadingCart: false,
  creatingcart: false,
  removing: ""
};

const cart = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case REMOVING_FROM_CART:
      return {
        ...state,
        removing: payload,
      };
    case GET_CART:
    case ADD_TO_CART:{
      localStorage.setItem("cart", JSON.stringify(payload))
      return {
        ...state,
        cart: payload,
      };
    }
    case GIFT_CARD:
      return {
        ...state,
        giftCard: payload,
      };
    case FETCHING_GIFTCARD: 
      return {
        ...state,
        fetchingGiftCard: payload,
      };
    case GIFT_CARD_RESET:
      return {
        ...state,
        giftCard: null
      };
    case CREATING_CART:
      return {
        ...state,
        creatingcart: payload,
      };
    case LOADING_CART:
      return {
        ...state,
        loadingCart: payload,
      };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        orderCreated: true,
      };
    case RESET_CART: {
      localStorage.removeItem("cart");
      return {
        ...state,
        cart: {},
        orderCreated: false,
      };
    }
    case ADD_TO_TEMP_CART:
      return {
        ...state,
        tempCart: payload,
      };
    case CLEAR_TEMP_CART:
      return {
        ...state,
        tempCart: {},
      };
    case REMOVE_TEMP_FROM_CART: {
      const products = state.cart?.products?.map(
        (cartItem) => cartItem.product !== payload
      );
      return {
        ...state,
        cart: { ...state.cart, products },
      };
    }
    case REMOVE_FROM_CART: {
      const cartProducts = [...state.cart.products];
      const products = cartProducts.filter(
        (cartItem) => cartItem._id !== payload
      );
      return {
        ...state,
        cart: { ...state.cart, products },
      };
    }
    default:
      return state;
  }
};

export default cart;
