export const SHOW_ALERT = "SHOW_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

const initialState = {
  show: false,
  heading: "",
  body: "",
};

const alert = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SHOW_ALERT:
      return { show: true, heading: payload.heading, body: payload.body, type: payload.type };
    case HIDE_ALERT:
      return { show: false, heading: "", body: "" };
    default:
      return state;
  }
};

export default alert;
