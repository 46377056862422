import React from "react";

const RadioGroup = ({ handleChange, groupName, options, title, value }) => {
  return (
    <div role="group" aria-labelledby={`label-${groupName}`}>
      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
        <div>
          <div
            className="text-base font-medium text-gray-900 sm:text-sm sm:text-gray-700"
            id={`label-${groupName}`}
          >
            {title}
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="max-w-lg">
            <div className="mt-4 space-x-4 flex items-center">
              {options.map((option, index) => (
                <div key={index} className="flex items-center">
                  <input
                    onChange={handleChange}
                    id={option.value}
                    name={groupName}
                    value={option.value}
                    type="radio"
                    className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300"
                    checked={option.value === value}
                  />
                  <label
                    htmlFor={option.value}
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    {option.name}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RadioGroup;
