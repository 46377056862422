import { usePaystackPayment } from 'react-paystack'

export const usePaystack = (email, amount, paymentRef, successCB, closeCB) => {
  const config = {
    reference: paymentRef,
    amount: amount * 100,
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    email
  };

  const onSuccess = (reference) => {
    successCB?.(reference);
  };

  // you can call this function anything
  const onClose = () => {
    closeCB?.();
  }

  const initializePayment = usePaystackPayment(config);
  return { initializePayment, onSuccess, onClose }
}