import React from "react";

const TextArea = ({ description, name, value, onChange, error }) => {
  return (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
      <label
        htmlFor="about"
        className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
      >
        {name}
      </label>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <textarea
          id={name}
          name={name}
          value={value}
          rows={3}
          onChange={onChange}
          className="max-w-lg shadow-sm block w-full focus:ring-green-500 focus:border-green-500 sm:text-sm border border-gray-300 rounded-md"
        />
        <p className="mt-2 text-sm text-gray-500">{description}</p>
        {error && <p className="text-xs text-red-600 mt-1">{error}</p>}
      </div>
    </div>
  );
};

export default TextArea;
