import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useCartProducts = (cart) => {
  const [cartDetails, setCartDetails] = useState([]);

  const { products } = useSelector(({ product }) => product);

  useEffect(() => {
    if (cart?.products) {
      const cartProducts = [];
      const cartProductsCopy = JSON.parse(JSON.stringify(cart.products));

      cartProductsCopy?.forEach((cartItem) => {
        const productCopy = JSON.parse(JSON.stringify(products.products));
        const currentProduct = cartItem;
        currentProduct["productDetails"] = productCopy.filter(
          (item) => item._id === cartItem.productId || item._id === cartItem.product
        )[0];

        cartProducts.push(currentProduct);
      });
      setCartDetails(cartProducts);
    }
  }, [cart.products, products]);

  return { cartDetails };
};
