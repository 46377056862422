import React from "react";
import { SAVED, TROLLEY } from ".";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Tabs = ({ setViewing, viewing }) => {
  const tabs = [
    { name: "Trolley", href: TROLLEY, current: viewing === TROLLEY },
    { name: "Saved", href: SAVED, current: viewing === SAVED },
  ];
  return (
    <div className="border-b border-gray-200">
      <div className="sm:flex sm:items-baseline">
        <div className="mt-4 sm:mt-0">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => setViewing(tab.href)}
                className={classNames(
                  tab.current
                    ? "border-indigo-500 text-indigo-600"
                    : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                  "whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Tabs;
