import React from "react";

const segments = [
  {
    heading: "Information We Collect",
    body: [
      {
        heading: "Personal Information:",
        body: "When you make a purchase or register for an account, we may collect personal information such as your name, email address, and shipping address.",
      },
      {
        heading: "User-Generated Content:",
        body: "If you choose to share photos or reviews of our products, we may collect and use that content with your permission.",
      },
      {
        heading: "Automatically Collected Information:",
        body: "We may collect information about your device, browsing actions, and patterns, such as your IP address, browser type, and referring website.",
      },
    ],
  },
  {
    heading: "How We Use Your Information:",
    body: [
      {
        heading: "Order Processing:",
        body: "We use your personal information to process and fulfil your orders, send order confirmations, and communicate with you about your purchases.",
      },
      {
        heading: "Customer Support:",
        body: "Your information helps us respond to your customer service requests and support needs more efficiently",
      },
      {
        heading: "Marketing Communications:",
        body: "With your consent, we may send you promotional emails about new products, special offers, and other updates.",
      },
      {
        heading: "Improving Our Services:",
        body: "We use data to analyze trends and gather demographic information, enabling us to improve our website, products, and customer experience.",
      },
    ],
  },
  {
    heading: "Information Sharing:",
    body: [
      {
        heading: "",
        body: "We do not sell, trade, or rent your personal information to third parties without your consent.",
      },
      {
        heading: "",
        body: "We may share your information with trusted service providers who assist us in operating our website, conducting our business, or servicing you, as long as those parties agree to keep your information confidential",
      },
    ],
  },
  {
    heading: "Security:",
    body: [
      {
        heading: "",
        body: "We implement a variety of security measures to maintain the safety of your personal information when you place an order or enter, submit, or access your personal information.",
      },
    ],
  },
  {
    heading: "Cookies:",
    body: [
      {
        heading: "",
        body: "We use cookies to enhance your experience on our site. You can choose to disable cookies through your browser settings.",
      },
    ],
  },
  {
    heading: "Your Rights:",
    body: [
      {
        heading: "",
        body: "You have the right to access, correct, update, or delete your personal information. You can do this by logging into your account or contacting us directly.",
      },
      {
        heading: "",
        body: "You have the right to opt out of receiving marketing emails. Instructions for opting out are included in each marketing communication.",
      },
    ],
  },
  {
    heading: "Changes to This Privacy Policy:",
    body: [
      {
        heading: "",
        body: "We may update our Privacy Policy from time to time. Any changes will be reflected on this page, and the date of the latest revision will be indicated at the top.",
      },
    ],
  },
  {
    heading: "Contact Us:",
    body: [
      {
        heading: "",
        body: "If you have any questions or concerns about our Privacy Policy, please contact us at maregroup.official@gmail.com",
      },
    ],
  },
];

const PrivacyPolicy = () => {
  return (
    <div className="max-w-2xl mb-12 mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <h1 className="text-3xl font-bold text-center my-10">Privacy Policy</h1>
      <p>
        Thank you for choosing Mare. This Privacy Policy is designed to help you
        understand how we collect, use, and safeguard your personal information.
        By using our website, purchasing our products, or engaging with us in
        any way, you consent to the practices described in this Privacy Policy.
      </p>
      {segments.map((segment, index) => (
        <Segment
          key={index}
          heading={segment.heading}
          index={index}
          body={segment.body}
        />
      ))}
      <p className="mt-4">
        By using our website or engaging with us in any way, you agree to the
        terms outlined in this Privacy Policy.
      </p>
    </div>
  );
};

const Segment = ({ heading, index, body }) => {
  return (
    <div className="mt-10">
      <h2 className="text-xl font-bold mt-2">
        {index + 1}. {heading}
      </h2>
      <p>
        {body.map((item, i) => {
          return (
            <div key={i}>
              {item.heading && (
                <p className="pt-4 text-lg font-semibold">
                  {item.heading ?? ""}
                </p>
              )}
              <div className="mt-2">{item.body}</div>
            </div>
          );
        })}
      </p>
    </div>
  );
};

export default PrivacyPolicy;
