import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrders } from "redux/action/adminActions";
import { CustomPageSectionLoader } from "components/Loaders";
import Modal from "components/basic/Modal";

const Orders = () => {
  const dispatch = useDispatch();
  const { loadingOrders, orders } = useSelector(({ admin }) => admin);
  const [openDetails, setOpenDetails] = useState(false);

  useEffect(() => {
    dispatch(getOrders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loadingOrders) {
    return <CustomPageSectionLoader />;
  }

  return (
    <div>
      <h1 className="text-2xl">Orders</h1>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Order Date/Number
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Price
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Paid
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    User
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {orders?.map((order) => (
                  <OrderDetails
                    openDetails={openDetails}
                    setOpenDetails={setOpenDetails}
                    key={order._id}
                    order={order}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const OrderDetails = ({ order, openDetails, setOpenDetails }) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });
  const getDate = () => {
    let myDate = new Date(order.createdAt);
    const offset = myDate.getTimezoneOffset();
    myDate = new Date(myDate.getTime() - offset * 60 * 1000);
    return myDate.toISOString().split("T")[0];
  };

  return (
    <tr
      className="cursor-pointer hover:shadow-lg"
      key={order.email}
      onClick={() => setOpenDetails(!openDetails)}
    >
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
        {getDate()} - {order.orderNumber}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {formatter.format(order.originalAmount / 100)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {formatter.format(order.paidAmount / 100)}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {order.user.name}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {order.status}
      </td>
      <Modal open={openDetails} setOpen={setOpenDetails}>
        <div className="">
          <h3 className="text-2xl mb-3 font-bold">User Details</h3>
          <ul>
            <li>Name: {order.user.name}</li>
            <li>Address: {order.user.address}</li>
            <li>Phone Number: {order.user.phone}</li>
            <li>Email: {order.user.email}</li>
          </ul>

          <h3 className="text-2xl my-3 font-bold">Order Details</h3>
          {order.cart.products.map((product, idx) => (
            <ul className="mb-2" key={idx}>
              <li className="font-semibold">{product.product.name}</li>
              <li className="flex items-center">
                Color:{" "}
                <div
                  style={{
                    backgroundColor: product.color,
                  }}
                  className="relative w-7 h-7 my-2 ml-2 flex border border-gray-500 shadow-2xl cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none"
                />
              </li>
              <li>Price: {product.product.price}</li>
            </ul>
          ))}
        </div>
      </Modal>
    </tr>
  );
};

export default Orders;
