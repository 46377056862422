import {configureStore, applyMiddleware} from '@reduxjs/toolkit'
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./reducer";
import { composeWithDevTools } from "redux-devtools-extension";

const logger = createLogger({
  diff: true,
  duration: true,
  logErrors: true,
  predicate: () => {
    switch (process.env.NODE_ENV) {
      case "test":
        return false;
      case "production":
        return false;
      default:
        return true;
    }
  },
});

export const middlewares = [thunk, logger];

export const initialState = {};

const devTools = composeWithDevTools(applyMiddleware(...middlewares));

const store = configureStore({reducer:rootReducer}, initialState, devTools);

export default store;
