export const GET_USER = "GET_USER";
export const LOGOUT = "LOGOUT";
export const BUY_GIFTCARD = "BUY_GIFTCARD";
export const CREATING_GIFTCARD = "CREATING_GIFTCARD";
export const GIFT_CARD_RESET = "GIFT_CARD_RESET";

const initialState = {
  isLoggedIn: localStorage.getItem("isLoggedIn")
  ? localStorage.getItem("isLoggedIn")
  : false,
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {},
  giftCard: {
    created: false,
    data: {},
    loading: false,
  },
};

const user = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_USER:
      return {
        ...state,
        user: payload,
        isLoggedIn: true,
      };
      case LOGOUT:
        return {
          ...state,
          user: {},
          isLoggedIn: false,
        };
    case BUY_GIFTCARD:
      return {
        ...state,
        giftCard: {
          created: true,
          data: payload,
          loading: false,
        },
      };
    case CREATING_GIFTCARD:
      return {
        ...state,
        giftCard: {
          ...state.giftCard,
          loading: payload,
        },
      };
    case GIFT_CARD_RESET:
      return {
        ...state,
        giftCard: {
          created: false,
          data: {},
          loading: false,
        },
      };
      
    default:
      return state;
  }
};

export default user;
