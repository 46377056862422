import Text from "components/input/Text";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createShop, getShops } from "redux/action/adminActions";

const Shops = () => {
  const dispatch = useDispatch();
  const { shops } = useSelector(({ admin }) => admin);

  useEffect(() => {
    dispatch(getShops());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [shopDetails, setShopDetails] = useState({
    shop_name: "",
    email: "",
    logo: "",
    admin_name: "",
    phone_number: "",
  });

  const handleChange = (e) => {
    setShopDetails({ ...shopDetails, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e, details) => {
    e.preventDefault();
    dispatch(
      createShop({
        shopName: details.shop_name,
        email: details.email,
        logo: details.logo,
        admin: {
          name: details.admin_name,
          phoneNumber: details.phone_number,
        },
      })
    );
  };

  return (
    <div>
      <h3 className="text-2xl font-semibold text-gray-900">Shops</h3>
      {shops &&
        shops?.map((shop, index) => (
          <div className="border-b border-gray-200 pb-2 mb-2" key={index}>
            <div className="font-extrabold mb-2">{shop.shopName}</div>
            <div className="bg-gray-200 font-extralight w-fit p-1 rounded-lg">
              api_key: {shop.apiKey}
            </div>
            <div className="font-semibold my-2">Admin</div>
            {Array.isArray(shop) ? (
              shop?.admin?.map((admin, idx) => <AdminDetails admin={admin} />)
            ) : (
              <AdminDetails admin={shop.admin} />
            )}
          </div>
        ))}

      <form className="mt-5" onSubmit={(e) => handleSubmit(e, shopDetails)}>
        <div className="flex justify-between">
          <h3 className="text-2xl font-semibold text-gray-900">Add Shop</h3>
          <button className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
            Save
          </button>
        </div>
        <Text
          label="Shop Name"
          onChange={(e) => handleChange(e)}
          name="shop_name"
          value={shopDetails.shop_name}
        />
        <Text
          label="Shop Mail"
          onChange={(e) => handleChange(e)}
          name="email"
          value={shopDetails.email}
        />
        <Text
          label="Shop Logo"
          onChange={(e) => handleChange(e)}
          name="logo"
          value={shopDetails.logo}
        />
        <Text
          label="Admin Name"
          onChange={(e) => handleChange(e)}
          name="admin_name"
          value={shopDetails.admin_name}
        />
        <Text
          label="Admin Phone"
          onChange={(e) => handleChange(e)}
          name="phone_number"
          value={shopDetails.phone_number}
        />
      </form>
    </div>
  );
};

const AdminDetails = ({ admin }) => {
  return (
    <div>
      <div>{admin.name}</div>
      <div>{admin.email}</div>
      <div>{admin.phoneNumber}</div>
    </div>
  );
};

export default Shops;
