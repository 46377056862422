import http from "plugin/http.client";
import {
  ADMIN_ADD_PRODUCT,
  ADMIN_DELETE_PRODUCTS,
  ADMIN_GET_PRODUCTS,
  ADMIN_GET_ORDERS,
  SET_LOADING_ORDERS,
  ADMIN_MODIFY_PRODUCT,
  GETTING_ALL_PRODUCTS,
  ADMIN_GET_SINGLE_PRODUCT,
  ADMIN_FETCH_PRODUCT,
  GET_SHOPS,
  ADD_SHOP,
} from "redux/reducer/admin";
import { handleError } from "utils/handleError";

// get products
export const getAllProducts = () => async (dispatch) => {
  dispatch({ type: GETTING_ALL_PRODUCTS, payload: true });
  try {
    const res = await http.get("/products?limit=100");
    localStorage.setItem("allProducts", JSON.stringify(res.data));
    dispatch({ type: ADMIN_GET_PRODUCTS, payload: res.data });
    dispatch({ type: GETTING_ALL_PRODUCTS, payload: false });
  } catch (error) {
    dispatch({ type: GETTING_ALL_PRODUCTS, payload: false });
    handleError(error, dispatch);
  }
};
// delete product
export const deleteProduct = (productId) => async (dispatch) => {
  try {
    const res = await http.delete(`admin/products/${productId}`);
    dispatch({ type: ADMIN_DELETE_PRODUCTS, payload: res.data });
  } catch (error) {
    handleError(error, dispatch);
  }
};
// add product
export const createProduct = (data, setSending) => async (dispatch) => {
  setSending(true);
  try {
    const res = await http.post("admin/products/create", data);
    dispatch({ type: ADMIN_ADD_PRODUCT, payload: res.data.product });
    setSending(false);
    window.location.replace("/admin");
  } catch (error) {
    setSending(false);
    handleError(error, dispatch);
  }
};
// edit product
export const editProduct = (id, data, setSending) => async (dispatch) => {
  setSending(true);
  try {
    const res = await http.put(`admin/products/${id}`, data);
    dispatch({
      type: ADMIN_MODIFY_PRODUCT,
      payload: { data: res.data?.product, id },
    });
    setSending(false);
    window.location.replace("/admin");
  } catch (error) {
    setSending(false);
    handleError(error, dispatch);
  }
};

// set product availability
export const setProductAvailability =
  (id, availability) => async (dispatch) => {
    try {
      const res = await http.patch(
        `admin/products/${id}/update-availability`,
        availability
      );
      dispatch({
        type: ADMIN_MODIFY_PRODUCT,
        payload: { data: res.data?.product, id },
      });
    } catch (error) {
      handleError(error, dispatch);
    }
  };

// get orders
export const getOrders = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING_ORDERS, payload: true });
    const res = await http.get("admin/orders");
    dispatch({ type: ADMIN_GET_ORDERS, payload: res.data.items });
  } catch (error) {
    handleError(error, dispatch);
    dispatch({ type: SET_LOADING_ORDERS, payload: false });
  }
};

// get single product
export const getSingleProduct = (id) => async (dispatch) => {
  if (localStorage.getItem("allProducts"))
    return dispatch({ type: ADMIN_GET_SINGLE_PRODUCT, payload: id });
  try {
    const res = await http.get(`/products/${id}`);
    dispatch({ type: ADMIN_FETCH_PRODUCT, payload: res.data.product });
  } catch (error) {
    handleError(error, dispatch);
  }
};

// create shop
export const createShop = (details) => async (dispatch) => {
  try {
    const res = await http.post("admin/shops", details);
    dispatch({ type: ADD_SHOP, payload: res.data.shop });
  } catch (error) {
    handleError(error, dispatch);
  }
};

export const getShops = () => async (dispatch) => {
  try {
    const res = await http.get("admin/shops");
    dispatch({ type: GET_SHOPS, payload: res.data.shops });
  } catch (error) {
    handleError(error, dispatch);
  }
};
