import React from "react";
import "./index.css";

/**
 * @function OverlayLoader
 * @description displays an overlay with a loader
 * @returns {JSX} returns a react node
 */
export const OverlayLoader = () => {
	return (
		<div className="overlay-wrapper" data-testid="overlay-loader">
			<div className="loader__container3">
				<svg
					version="1.1"
					id="L4"
					xmlns="http://www.w3.org/2000/svg"
					x="0px"
					y="0px"
					viewBox="0 0 50 100"
					enableBackground="new 0 0 0 0"
					xmlSpace="preserve"
				>
					<circle fill="#000000" stroke="none" cx="6" cy="50" r="6">
						<animate
							attributeName="opacity"
							dur="1s"
							values="0;1;0"
							repeatCount="indefinite"
							begin="0.1"
						/>
					</circle>
					<circle fill="#000000" stroke="none" cx="26" cy="50" r="6">
						<animate
							attributeName="opacity"
							dur="1s"
							values="0;1;0"
							repeatCount="indefinite"
							begin="0.2"
						/>
					</circle>
					<circle fill="#000000" stroke="none" cx="46" cy="50" r="6">
						<animate
							attributeName="opacity"
							dur="1s"
							values="0;1;0"
							repeatCount="indefinite"
							begin="0.3"
						/>
					</circle>
				</svg>
			</div>
		</div>
	);
};

/**
 * @function CustomPageSectionLoader
 * @description This component serves as a section loader (Page section loader)
 * @summary Its parent must have a position of relative
 * @returns {JSX} - returns Javascript Syntax Extension
 */
export const CustomPageSectionLoader = () => (
	<div 
	className="flex justify-center items-center h-96 w-screen"
	data-testid="custom-loader"
	>
		<svg
			version="1.1"
			id="L4"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			width="200" height="200"
			viewBox="0 0 50 100"
			enableBackground="new 0 0 0 0"
			xmlSpace="preserve"
		>
			<circle fill="#000000" stroke="none" cx="6" cy="50" r="6">
				<animate
					attributeName="opacity"
					dur="1s"
					values="0;1;0"
					repeatCount="indefinite"
					begin="0.1"
				/>
			</circle>
			<circle fill="#000000" stroke="none" cx="26" cy="50" r="6">
				<animate
					attributeName="opacity"
					dur="1s"
					values="0;1;0"
					repeatCount="indefinite"
					begin="0.2"
				/>
			</circle>
			<circle fill="#000000" stroke="none" cx="46" cy="50" r="6">
				<animate
					attributeName="opacity"
					dur="1s"
					values="0;1;0"
					repeatCount="indefinite"
					begin="0.3"
				/>
			</circle>
		</svg>
	</div>
);

// loader from https://codepen.io/nikhil8krishnan/pen/rVoXJa
