import React from "react";
import TopNav from "components/layout/TopNav";
import Socials from "components/layout/Socials";

const Wrapper = ({ children }) => {
  // const {pathname} = useLocation()
  return (
    <div>
      {/* {pathname !== "/" && <Updating />} */}
      <TopNav />
      <div className="pb-20">{children}</div>
      <Socials />
    </div>
  );
};

export default Wrapper;
