/* eslint-disable no-mixed-spaces-and-tabs */
import React, { Component } from "react";
import propTypes from "prop-types";
import NotFound from "./NotFound";

class ErrorBoundary extends Component {
  state = { hasError: false, error: "" };
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    this.setState({ hasError: true });
    console.log({ error, info });
  }

  render() {
    return this.state.hasError ? <NotFound /> : this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: propTypes.oneOfType([propTypes.object, propTypes.array]).isRequired,
};

export default ErrorBoundary;
