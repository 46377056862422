import { SHOW_ALERT } from "redux/reducer/alert";

const getBody = (error) => {
  console.log({ error });
  if (error?.response?.data?.message) {
    return JSON.parse(error?.response?.data?.message);
  }
  if (error?.message) {
    return error.message;
  }
  if (error) {
    return JSON.parse(error);
  }

  return "ooops something went wrong";
};

export const handleError = (error, dispatch) => {
  dispatch({
    type: SHOW_ALERT,
    payload: {
      heading: "Oops, something went wrong",
      body: getBody(error),
      type: "error",
    },
  });
};
